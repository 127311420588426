<script setup lang="ts">
import { useMediaQuery } from '@vueuse/core';
import { animate, scroll } from 'motion';
defineProps<{
    recentContent: any;
}>();

// Future: Use Breakpoints from Tailwind instead
// const breakpoints = useBreakpoints(breakpointsTailwind)
const isLargeScreen = useMediaQuery('(min-width: 1024px)');

const runtimeConfig = useRuntimeConfig();
const content_Url = runtimeConfig.public.karriere.content.url;

const listContainerRef = ref();
const scrollAnimations = ref([]);

function animateScroll() {
    if (!isLargeScreen.value) {
        cleanupScrollAnimations();
        return;
    }

    const cards = Array.from(listContainerRef.value.children);

    cards.forEach((card, index0) => {
        const numCards = cards.length;
        const index = index0 + 1;
        const reverseIndex = numCards - index;

        card.style.paddingTop = `calc(${index} * 0.5rem)`;

        scrollAnimations.value.push(
            scroll(
                animate(card, {
                    filter: ['brightness(1)', 'brightness(0.8)'],
                    scale: [1, 1 - 0.025 * reverseIndex],
                }),
                {
                    offset: [
                        `${(index0 / numCards) * 100}%`,
                        `${(index / numCards) * 100}%`,
                    ],
                    target: listContainerRef.value,
                },
            ),
        );
    });
}

function cleanupScrollAnimations() {
    if (!scrollAnimations.value?.length) return;

    scrollAnimations.value = [];
}

onMounted(() => animateScroll());
watch(isLargeScreen, () => {
    animateScroll();
});
</script>

<template>
    <section
        class="grid w-full pt-11 lg:grid-cols-2 lg:gap-8 lg:pt-16"
        style="content-visibility: auto"
    >
        <div class="top-0 flex flex-col gap-4 lg:sticky">
            <div class="top-[60px] flex flex-col gap-4 pt-5 lg:sticky">
                <BaseHeadline
                    text="Expertise aus erster Hand"
                    underline
                    as="h2"
                    class="lg:text-inherit text-xl"
                >
                    Expertise aus erster Hand
                </BaseHeadline>
                <BaseText
                    >Exklusive Einblicke in HR-Themen in Kooperation mit
                    externen Partnern und
                    Meinungsforschungsinstituten.</BaseText
                >
                <NuxtImg
                    width="480"
                    height="522"
                    decoding="auto"
                    src="/illustrations/statistics.svg"
                    class="mt-[6.25rem] hidden lg:block"
                    alt="Person die verschiedenste Grafiken und Statistiken präsentiert"
                    loading="lazy"
                />
            </div>
        </div>
        <div class="flex flex-col items-center gap-6 pt-5">
            <ul ref="listContainerRef" class="flex w-full flex-col">
                <li
                    v-for="content in recentContent"
                    :key="content.id"
                    class="mb-4 md:mb-0 lg:sticky lg:top-[60px] lg:origin-top"
                >
                    <MainContentCard v-bind="content" />
                </li>
            </ul>
            <JamBaseLink variant="dark-gray" :to="`${content_Url}/hr/tipps`">
                Alle Downloads
            </JamBaseLink>
        </div>
    </section>
</template>
