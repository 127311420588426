<script setup lang="ts">
const { showBundleCampaign } = useBundleCampaign();
</script>

<template>
    <section
        id="products"
        class="grid grid-cols-1 gap-6 pt-8 lg:grid-cols-12 lg:pt-9"
        style="content-visibility: auto"
    >
        <h2 class="sr-only">Unsere Produkte</h2>
        <MainCardProductCategory
            headline="Stelleninserate"
            description="Maximieren Sie Ihre Reichweite mit unseren Stelleninseraten auf Österreichs größtem Karriereportal."
            pricing="ab € 635,-"
            image="/images/main/home-stellenanzeige.webp"
            image-alt='Ein Premium-Inserat auf karriere.at für die Position "Software Engineer". Es umfasst ein Headerbild, wichtige Infos zur Stelle und Angaben zu Gehalt und Benefits.'
            image2="/images/main/home-stellenanzeige-2.webp"
            image2-alt="Die Reporting-Seite im Business Portal umfasst Auswertungen zur Inserate-Performance in Bezug auf Reichweite, Interaktionen und Bewerbungen."
            href="/produkte/stelleninserate"
            class="lg:col-start-1 lg:col-end-5 lg:row-start-1 lg:row-end-1"
            data-gtm-element="BP_EC: Home Product Selection"
            data-gtm-element-detail="Stelleninserate"
        >
            <template #performanceTeaser>
                <div>
                    Bis zu
                    <span class="text-xl font-semibold">41.000</span>
                    <br />Jobsuchende täglich <br />
                    auf karriere.at
                    <CommBaseCitationLink :number="4" />
                </div>
            </template>
        </MainCardProductCategory>

        <MainCardProductCategory
            headline="Aktive Talentsuche"
            pricing="ab € 570,-"
            image="/images/main/home-talentsuche.webp"
            image-alt='Überblick über das Active-Sourcing-Produkt. Links sind mehrere Talente als Muster dargestellt, rechts das Profil eines Talents im Berufsfeld "Buchhaltungsfachkraft mit Schwerpunkt landwirtschaftliche Betriebe". Das Profil enthält Infos zur Verfügbarkeit, bevorzugten Arbeitsorten, Gehaltsvorstellungen sowie den Lebenslauf, Weiterbildung und Anhänge. Unten rechts ist ein "Anfrage senden"-Button.'
            image2="/images/main/home-talentsuche-2.webp"
            image2-alt="Die Chat-Funktion des Active-Sourcing-Produkts mit der Möglichkeit, eine Datei anzuhängen, eine Nachricht zu verschicken und den Chatverlauf einzusehen."
            href="/produkte/talentsuche"
            class="lg:col-start-5 lg:col-end-9 lg:row-start-1 lg:row-end-1"
            data-gtm-element="BP_EC: Home Product Selection"
            data-gtm-element-detail="Aktive Talentsuche"
        >
            <template #description>
                <JamBaseText>
                    40 % der Talente sind wechselbereit, suchen aber nicht
                    aktiv. Diese erreichen Sie mit unserer Talentdatenbank.
                    <CommBaseCitationLink :number="6" />
                </JamBaseText>
            </template>
            <template #performanceTeaser>
                <div>
                    <span class="text-xl font-semibold">150.000+</span>
                    <br />Talente in der<br />
                    Talentdatenbank
                    <CommBaseCitationLink :number="5" />
                </div>
            </template>
        </MainCardProductCategory>

        <MainCardProductCategory
            headline="Arbeitgeberprofil"
            pricing="ab € 1.990,-"
            image="/images/main/home-arbeitgeberprofil.webp"
            image-alt="Ein Arbeitgeberprofil Plus auf karriere.at für eine Musterfirma. Es enthält ein Headerbild, Videos und Eckdaten wie Unternehmensgröße, Branche, Standorte, gesuchte Positionen und Benefits."
            image2="/images/main/home-arbeitgeberprofil-2.webp"
            image2-alt='Eingabemaske im Business Portal für das Arbeitgeberprofil Plus. Sie zeigt unter anderem die Upload-Funktion für das Headerbild und Felder für Zahlen, Fakten und Unternehmensinfos. Es gibt zwei CTA-Buttons: "Vorschau anzeigen" und "Änderungen veröffentlichen".'
            href="/produkte/arbeitgeberprofil"
            class="lg:col-start-1 lg:col-end-9 lg:row-start-2 lg:row-end-2"
            data-gtm-element="BP_EC: Home Product Selection"
            data-gtm-element-detail="Arbeitgeberprofil"
        >
            <template #description>
                <JamBaseText>
                    Jede*r Dritte bewirbt sich nicht, wenn Unternehmensinfos
                    fehlen. Mit dem Arbeitgeberprofil erhöhen Sie Ihre
                    Attraktivität und sprechen passende Talente an.
                    <CommBaseCitationLink :number="6" />
                </JamBaseText>
            </template>
            <template #performanceTeaser>
                <div>
                    <span class="text-xl font-semibold">83 %</span> <br />
                    der Talente möchten Einblicke ins Unternehmen
                    <CommBaseCitationLink :number="6" />
                </div>
            </template>
        </MainCardProductCategory>

        <CommCampaignCardMain
            v-if="showBundleCampaign"
            class="lg:col-start-9 lg:col-end-13 lg:row-start-1 lg:row-end-3"
        />
        <CommCardProduktberater
            v-else
            class="bg-gray-50 lg:col-start-9 lg:col-end-13 lg:row-start-1 lg:row-end-3"
        />
    </section>
</template>
