<template>
    <section class="flex flex-col gap-6 pt-7 lg:items-start lg:gap-9 lg:pt-11">
        <BaseHeadline
            as="h1"
            underline
            text="Stellen erfolgreich besetzen mit Österreichs größtem
                Karriereportal"
            class="max-w-[35ch] !text-xl md:!text-3xl lg:text-left lg:!text-4xl"
        >
            Stellen erfolgreich besetzen mit Österreichs größtem
            Karriereportal</BaseHeadline
        >
        <ul class="grid w-full gap-6 md:grid-cols-3">
            <li>
                <span class="flex items-baseline gap-3">
                    <span class="text-2xl font-semibold lg:text-5xl"
                        >85 %
                    </span>
                </span>
                <span class="text-sm lg:text-base"
                    ><span class="font-semibold">Bekanntheit</span> bei
                    erwerbsfähigen Personen
                    <sup
                        ><JamBaseLink
                            id="footnote-1-link"
                            variant="dark-gray"
                            href="#footnote-1"
                            class="scroll-mt-[12em] text-xs"
                            >1</JamBaseLink
                        ></sup
                    ></span
                >
            </li>
            <li>
                <span class="flex items-baseline gap-3">
                    Bis zu
                    <span class="text-2xl font-semibold lg:text-5xl"
                        >1.3 Mio.
                    </span>
                </span>
                <span class="text-sm lg:text-base"
                    ><span class="font-semibold">Jobsuchende</span> auf
                    karriere.at monatlich
                    <sup>
                        <JamBaseLink
                            id="footnote-2-link"
                            variant="dark-gray"
                            href="#footnote-2"
                            class="scroll-mt-[12em] text-xs"
                            >2</JamBaseLink
                        ></sup
                    >
                </span>
            </li>
            <li>
                <span class="flex items-baseline gap-3">
                    <span class="text-2xl font-semibold lg:text-5xl"
                        >96 %
                    </span>
                </span>
                <span class="text-sm lg:text-base"
                    ><span class="font-semibold">Kundenloyalität.</span> Wir
                    sind langfristiger Partner.
                    <sup>
                        <JamBaseLink
                            id="footnote-3-link"
                            variant="dark-gray"
                            href="#footnote-3"
                            class="scroll-mt-[12em] text-xs"
                            >3</JamBaseLink
                        ></sup
                    >
                </span>
            </li>
        </ul>
    </section>
</template>
