import type { Testimonial } from '~/packages/commerce/types/testimonial';

export const testimonials: Testimonial[] = [
    {
        companyLogo: '/images/testimonials/b_r_logo.webp',
        companyName: 'B & R',
        id: 1,
        kpis: [
            {
                headline: '50%',
                subline: 'Rückmelderate',
            },
            {
                headline: '1 Tag',
                subline: 'Antwortzeit',
            },
            {
                headline: 'Jeder 2.',
                subline: 'Kontakt wird eingeladen',
            },
        ],
        link: `https://www.karriere.at/hr/case-study/case-study-bernecker-rainer`,
        person: {
            name: 'Daniela Staller',
            title: 'Human Capital · B&R Industrial Automation GmbH',
        },
        previewText: 'Aktiv Fachkräfte rekrutieren mit der Talentdatenbank',
        text:
            'Über die Talentdatenbank rekrutieren wir für technische Positionen  innerhalb kürzester Zeit ' +
            'Top-Fachkräfte, die wir sonst nur schwer erreichen!',
    },
    {
        companyLogo: '/images/testimonials/otto-immobilien_logo.svg',
        companyName: 'Otto Immobilien GmbH',
        id: 2,
        kpis: [
            {
                headline: '55',
                subline: 'Tage Time-to-fill',
            },
            {
                headline: 'Höhere',
                subline: 'Bekanntheit',
            },
            {
                headline: '90 %',
                subline: 'zufriedene Bewerber*innen',
            },
        ],
        link: `https://www.karriere.at/hr/case-study/case-study-otto-immobilien`,
        person: {
            name: 'Julia Röder',
            title: 'Leiterin Human Ressources und Organisationsentwicklung',
        },
        previewText:
            'Kürzere Besetzungsdauer durch eine erfolgreiche Candidate Journey',
        text:
            'Mit dem Arbeitgeberprofil Plus können wir Bewerber*innen viel von unserem Unternehmen zeigen. ' +
            'Sei es die Präsentation von Mitarbeitenden oder der allgemeine Einblick in Otto Immobilien – wir können ' +
            'uns greifbarer machen. karriere.at ist die größte Karriereplattform Österreichs und dort präsent zu sein ' +
            'ist zu 100 % notwendig.',
    },
    {
        companyLogo: '/images/testimonials/eegger_logo.png',
        companyName: 'Eegger',
        id: 3,
        kpis: [
            {
                headline: '3 von 4',
                subline:
                    'Arbeitnehmern sagen, dass eine Stellenausschreibung hohen Einfluss auf Ihre Meinung zu einem Arbeitgeber hat.',
            },
            {
                headline: '7 von 10',
                subline:
                    'Jobsuchenden beschäftigen sich online intensiv mit dem Unternehmen, bevor sie sich bewerben.',
            },
            {
                headline: '1/3',
                subline:
                    'der Jobsuchenden wünscht sich vorab mehr über Leitbild und Benefits zu erfahren.',
            },
        ],
        link: `https://www.karriere.at/hr/case-study/case-study-egger`,
        person: {
            name: 'Angelika Seeländer',
            title: 'Human Resources St. Johann in Tirol · EGGER',
        },
        previewText: 'Starke Arbeitgebermarke für erfolgreiches Recruiting',
        text:
            'Mit der branding.solution hat sich die Anzahl und Qualität der  Bewerbungen erhöht. Trotz der ' +
            'ländlichen Lage unseres Headquarters.',
    },
];
