<script setup lang="ts">
import { testimonials } from '../../texts/testimonials';

const selectedTestimonial = ref(testimonials[0]);

const runtimeConfig = useRuntimeConfig();
const content_Url = runtimeConfig.public.karriere.content.url;
</script>

<template>
    <section class="flex flex-col gap-8 pt-16" style="content-visibility: auto">
        <div class="flex flex-col gap-4">
            <BaseHeadline
                text="Unsere Kunden vertrauen beim Recruiting auf uns. "
                underline
                as="h2"
                class="text-xl"
                >Unsere Kunden vertrauen beim Recruiting auf uns.
            </BaseHeadline>
            <JamBaseText class="max-w-[80ch]">
                97 % unserer Kunden sehen uns als langfristigen Partner.
                Erfahren Sie, wie wir verschiedenste Branchen erfolgreich
                unterstützen.
            </JamBaseText>
        </div>

        <!-- MOBILE -->
        <div class="flex flex-col lg:hidden">
            <JamBaseCollapse
                v-for="(testimonial, index) in testimonials"
                :key="`${testimonial}-${index}`"
                :trigger-text="testimonial.previewText"
                class="border-b"
                trigger-position="top"
                trigger-variant="dark-gray"
                trigger-classes="w-full"
                trigger-content-classes="justify-between w-full py-3 font-semibold text-left"
                trigger-content-gap="gap-4"
                :open="selectedTestimonial.id == testimonial.id"
                @click="selectedTestimonial = testimonial"
            >
                <div class="mb-4 flex items-start gap-5 rounded border p-5">
                    <div class="flex items-start gap-5">
                        <div class="grid gap-12 lg:grid-cols-[1fr_200px]">
                            <div class="flex items-start gap-5">
                                <img src="/quote.svg" alt="" class="size-6" />
                                <div class="flex flex-col gap-6">
                                    <div>
                                        <p
                                            class="text-lg font-semibold lg:text-2xl"
                                        >
                                            {{ testimonial.text }}
                                        </p>
                                        <div v-if="testimonial.person">
                                            <div class="mt-4">
                                                <JamBaseText variant="bold">{{
                                                    testimonial.person.name
                                                }}</JamBaseText>
                                                <JamBaseText
                                                    variant="small"
                                                    class="text-gray-500"
                                                    >{{
                                                        testimonial.person.title
                                                    }}</JamBaseText
                                                >
                                            </div>
                                        </div>
                                        <div
                                            class="my-6 flex items-center gap-6"
                                        >
                                            <NuxtImg
                                                :src="testimonial.companyLogo"
                                                class="h-10 object-contain"
                                                :alt="`Logo von der Firma ${testimonial.companyName}`"
                                                loading="lazy"
                                                decoding="auto"
                                            />
                                        </div>
                                        <hr />
                                        <ul
                                            v-if="testimonial.kpis.length"
                                            class="mt-5 flex w-full flex-col flex-wrap justify-between gap-3 lg:mt-9 lg:flex-row"
                                        >
                                            <li
                                                v-for="(
                                                    kpi, mobileKpiIndex
                                                ) in testimonial.kpis"
                                                :key="`${kpi.headline}-${mobileKpiIndex}`"
                                            >
                                                <p class="flex flex-col">
                                                    <span
                                                        class="text-lg font-semibold lg:text-xl"
                                                        >{{
                                                            kpi.headline
                                                        }}</span
                                                    >
                                                    <span>{{
                                                        kpi.subline
                                                    }}</span>
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </JamBaseCollapse>
            <div class="mt-6 flex w-full justify-center">
                <JamBaseLink
                    variant="dark-gray"
                    :to="`${content_Url}/hr/case-studies`"
                    external
                >
                    Alle Kundenreferenzen
                </JamBaseLink>
            </div>
        </div>
        <!-- DESKTOP -->
        <CommBaseTabsVertical
            class="mt-3 hidden lg:grid"
            tab-list-classes="col-span-3"
            tab-panel-classes="col-span-9"
        >
            <template #tabs>
                <CommBaseTabVertical
                    v-for="(testimonial, index) in testimonials"
                    :key="`testimonial-tab-${index}`"
                    variant="dark"
                    class="flex flex-col items-start gap-5 text-start"
                >
                    {{ testimonial.previewText }}
                </CommBaseTabVertical>
                <JamBaseLink
                    variant="dark-gray"
                    class="ml-6"
                    :to="`${content_Url}/hr/case-studies`"
                >
                    Alle Kundenreferenzen
                </JamBaseLink>
            </template>
            <template #panels>
                <HeadlessTabPanel
                    v-for="(testimonial, index) in testimonials"
                    :key="`testimonial-panel-${index}`"
                >
                    <NuxtLink :to="`${testimonial.link}`">
                        <div
                            class="flex items-start gap-5 rounded border p-7 transition-all duration-300 hover:shadow"
                        >
                            <div class="flex items-start gap-5">
                                <div class="flex gap-11">
                                    <div class="flex items-start gap-5">
                                        <img src="/quote.svg" alt="" />
                                        <div class="flex flex-col gap-5">
                                            <div>
                                                <p
                                                    class="text-xl font-semibold"
                                                >
                                                    {{ testimonial.text }}
                                                </p>

                                                <div class="mt-5">
                                                    <JamBaseText
                                                        variant="bold"
                                                        >{{
                                                            testimonial.person
                                                                .name
                                                        }}</JamBaseText
                                                    >
                                                    <JamBaseText
                                                        variant="small"
                                                        class="mb-6 text-gray-500"
                                                    >
                                                        {{
                                                            testimonial.person
                                                                .title
                                                        }}
                                                    </JamBaseText>
                                                    <hr />
                                                </div>
                                                <ul
                                                    v-if="
                                                        testimonial.kpis.length
                                                    "
                                                    class="mt-6 flex w-full justify-between gap-8"
                                                >
                                                    <li
                                                        v-for="(
                                                            kpi, kpiIndex
                                                        ) in testimonial.kpis"
                                                        :key="`${kpi.headline}-${kpiIndex}`"
                                                    >
                                                        <p
                                                            class="flex flex-col"
                                                        >
                                                            <span
                                                                class="text-xl font-semibold"
                                                                >{{
                                                                    kpi.headline
                                                                }}</span
                                                            >
                                                            <span>{{
                                                                kpi.subline
                                                            }}</span>
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-full max-w-[12.5rem]">
                                        <div class="flex items-center gap-6">
                                            <NuxtImg
                                                :src="testimonial.companyLogo"
                                                class="h-10 object-contain"
                                                :alt="`Logo von der Firma ${testimonial.companyName}`"
                                                loading="lazy"
                                                decoding="auto"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </NuxtLink>
                </HeadlessTabPanel>
            </template>
        </CommBaseTabsVertical>
    </section>
</template>
