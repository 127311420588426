<script setup lang="ts">
import { indexPage } from '../texts/citations';

definePageMeta({
    isAuthRequired: false,
    layout: 'content',
});

const seoTitle = 'Recruiting-Lösungen vom Marktführer';
const seoDescription =
    'Die besten HR-Lösungen von Österreichs größtem Karriereportal ▶ Stelleninserate ✓ Kandidatensuche ☆ Employer Branding';

const runtimeConfig = useRuntimeConfig();
const content_Url = runtimeConfig.public.karriere.content.url;
const recentContent = [
    {
        category: 'Arbeitsmarktreport',
        description:
            'Für den karriere.at Arbeitsmarktreport wurden über 500.000  Stelleninserate auf karriere.at aus den letzten Jahren ausgewertet, um  ein detailliertes Bild der aktuellen Situation am österreichischen Arbeitsmarkt zu erhalten. Hier gehts zu den Erkenntnissen.',
        id: 2,
        link: `${content_Url}/hr/t/arbeitsmarktreport-2025`,
        title: 'karriere.at Arbeitsmarktreport (Februar 2025)',
    },
    {
        category: 'Recruiting',
        description:
            '40 % der Arbeitnehmer*innen sind wechselbereit, wenn die Rahmenbedingungen stimmen. Diese Menschen erreichen Arbeitgeber durch Active Sourcing, die Direktansprache von Talenten. Was dabei zu beachten ist, erfahren Sie in diesem Factsheet.',
        id: 1,
        link: `${content_Url}/hr/t/active-sourcing`,
        title: 'Besseres Recruiting mit Active Sourcing',
    },
    {
        category: 'Employer Branding',
        description:
            'Wie viel wollen, können oder müssen Arbeitnehmer*innen in Österreich arbeiten? Die neue karriere.at-Studie gibt anhand aktueller Daten Aufschluss über diese Fragen und zeigt sowohl Geschlechter- als auch Generationenunterschiede auf. Lesen Sie selbst!',
        id: 3,
        link: `${content_Url}/hr/t/arbeitszeit-im-wandel`,
        title: 'Arbeitszeit im Wandel',
    },
];

useSeoMeta({
    description: seoDescription,
    ogDescription: seoDescription,
    ogTitle: seoTitle,
    robots: 'index, follow',
    title: seoTitle,
});
</script>

<template>
    <div>
        <div class="mx-auto flex max-w-content flex-col px-5 lg:px-7">
            <MainHero />
            <MainProducts />
            <MainTestimonials />
            <MainRecentContent :recent-content="recentContent" />
            <MainCitations :citations="indexPage" />
        </div>
        <MainContact />
    </div>
</template>
